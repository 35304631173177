import Trigger from '../Trigger';

export default class InactivityTrigger extends Trigger {

    static TRIGGER_NAME = 'inactivity';

    inactivityTimeout = this.params.inactivityTimeout || 10; //seconds

    timerId = null;

    init() {
        const events = 'mousemove mouseleave click keydown touchstart touchmove touchend',
            resetTimer = () => {
                this.timerId && clearTimeout(this.timerId);
                this.timerId = setTimeout(() => {
                    $(document).off(events, resetTimer);
                    this.execute();
                }, this.inactivityTimeout * 1000);
            };

        $(document).on(events, resetTimer);

        resetTimer();
    }
}
