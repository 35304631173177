import TriggerManager from './TriggerManager';
import ClickTrigger from './actions/ClickTrigger';
import TimeoutTrigger from './actions/TimeoutTrigger';
import LeaveTrigger from "./actions/LeaveTrigger";
import ScrollTrigger from "./actions/ScrollTrigger";
import InactivityTrigger from "./actions/InactivityTrigger";
import ImmediatelyTrigger from "./actions/ImmediatelyTrigger";

window.upsellTriggerManager = new TriggerManager([
    ImmediatelyTrigger,
    InactivityTrigger,
    ScrollTrigger,
    TimeoutTrigger,
    ClickTrigger,
    LeaveTrigger,
]);
