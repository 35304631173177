import Trigger from '../Trigger';

export default class TimeoutTrigger extends Trigger {

    static TRIGGER_NAME = 'timeout';

    /**
     * Seconds
     */
    timeout = this.params.timeout || 10;

    timerId = null;

    init() {
        this.timerId = setTimeout(() => {
            this.timerId && clearTimeout(this.timerId);
            this.execute();
        }, this.timeout * 1000);
    }
}
